import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import VideoModal from "../../components/videoModal"

class LostAndFoundCollection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstTabOn: true,
      videoModalOpen: false
    }

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  toggleTab(e) {
    this.setState(prevState => ({
      firstTabOn: !prevState.firstTabOn 
    }))
  }

  toggleVideoModal(e) {
    this.setState(prevState => ({
      videoModalOpen: !prevState.videoModalOpen 
    }))
  }

  render() {
    return (
      <Layout>
        <SEO title="Lost and Found Collection" />

        <section className="hero-banner">
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.L95A0481_banner.childImageSharp.fluid} />
              <h1 className="hero-banner__title">
                Lost and Found
              </h1>
              <h2 className="hero-banner__subtitle">
                For the "Lost and Found" Collection my inspiration comes from a turning point in humanity's history, The Holocaust, and attempts to answer the questions in a creative perspective. It’s a real journey into the day to day life, you are there with them, you can almost feel the pain, the fear, the cold. I will never find the “right” words or a rational answer, I am just glad that I can show it through my works.
              </h2>
            </div>
          </div>
        </section>

        <button onClick={this.toggleVideoModal} className="watch-video-btn button is-dark">Watch Video</button>
        <VideoModal videoId="-QlaNhoa_Lw" videoModalOpen={this.state.videoModalOpen} toggleVideoModal={this.toggleVideoModal} />

        <div className="tabs is-centered">
          <ul>
            <li className={ this.state.firstTabOn ? "is-active" : " "} onClick={this.toggleTab}>
              <a>Campaign</a>
              </li>
            <li className={ this.state.firstTabOn ? " " : "is-active"} onClick={this.toggleTab}>
              <a>Look book</a>
              </li>
          </ul>
        </div>

        <section className="collection-wrapper">
          <div id="tab-content">
            <div className={ this.state.firstTabOn ? "is-active" : " "} data-content="1">
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.L95A0191.childImageSharp.fluid} />
                  <Img fluid={this.props.data.L95A0198.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.L95A0217.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.L95A0309.childImageSharp.fluid} className="next-image" />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.L95A0389.childImageSharp.fluid} />
                  <Img fluid={this.props.data.L95A0392.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.L95A0481.childImageSharp.fluid} className="next-image" />
                </div>
              </div>
            </div>
            <div className={ this.state.firstTabOn ? " " : "is-active"} data-content="2">
              
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look1_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look1_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look1_3.childImageSharp.fluid} />
                </div>
              </div>
                
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look2_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look2_3.childImageSharp.fluid} />
                </div>
              </div>  

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look3_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look3_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look3_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look4_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look4_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look4_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look5_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look5_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look5_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look6_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look6_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look6_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look7_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look7_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look7_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look8_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look8_2.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look9_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look9_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look9_3.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look10_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look10_2.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">  
                <div className="column">
                  <Img fluid={this.props.data.Look10_3.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look10_4.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.Look11_1.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look11_2.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.Look11_3.childImageSharp.fluid} />
                </div>
              </div>
              
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

LostAndFoundCollection.propTypes = {
  
}

export default LostAndFoundCollection

export const fluidImageLostAndFoundBanner = graphql`
fragment fluidImageLostAndFoundBanner on File {
  childImageSharp {
    fluid(maxWidth: 2560) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidImageLostAndFound = graphql`
fragment fluidImageLostAndFound on File {
  childImageSharp {
    fluid(maxWidth: 1280) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    L95A0481_banner: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0481_banner.jpg" }) {
      ...fluidImageLostAndFoundBanner
    }
    L95A0191: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0191.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0198: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0198.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0217: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0217.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0309: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0309.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0389: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0389.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0392: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0392.jpg" }) {
      ...fluidImageLostAndFound
    }
    L95A0481: file(relativePath: { eq: "collections/lost-and-found/campaign/L95A0481.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look10_1: file(relativePath: { eq: "collections/lost-and-found/Look10_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look10_2: file(relativePath: { eq: "collections/lost-and-found/Look10_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look10_3: file(relativePath: { eq: "collections/lost-and-found/Look10_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look10_4: file(relativePath: { eq: "collections/lost-and-found/Look10_4.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look11_1: file(relativePath: { eq: "collections/lost-and-found/Look11_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look11_2: file(relativePath: { eq: "collections/lost-and-found/Look11_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look11_3: file(relativePath: { eq: "collections/lost-and-found/Look11_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look1_1: file(relativePath: { eq: "collections/lost-and-found/Look1_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look1_2: file(relativePath: { eq: "collections/lost-and-found/Look1_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look1_3: file(relativePath: { eq: "collections/lost-and-found/Look1_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look2_1: file(relativePath: { eq: "collections/lost-and-found/Look2_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look2_3: file(relativePath: { eq: "collections/lost-and-found/Look2_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look3_1: file(relativePath: { eq: "collections/lost-and-found/Look3_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look3_2: file(relativePath: { eq: "collections/lost-and-found/Look3_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look3_3: file(relativePath: { eq: "collections/lost-and-found/Look3_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look4_1: file(relativePath: { eq: "collections/lost-and-found/Look4_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look4_2: file(relativePath: { eq: "collections/lost-and-found/Look4_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look4_3: file(relativePath: { eq: "collections/lost-and-found/Look4_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look5_1: file(relativePath: { eq: "collections/lost-and-found/Look5_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look5_2: file(relativePath: { eq: "collections/lost-and-found/Look5_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look5_3: file(relativePath: { eq: "collections/lost-and-found/Look5_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look6_1: file(relativePath: { eq: "collections/lost-and-found/Look6_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look6_2: file(relativePath: { eq: "collections/lost-and-found/Look6_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look6_3: file(relativePath: { eq: "collections/lost-and-found/Look6_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look7_1: file(relativePath: { eq: "collections/lost-and-found/Look7_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look7_2: file(relativePath: { eq: "collections/lost-and-found/Look7_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look7_3: file(relativePath: { eq: "collections/lost-and-found/Look7_3.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look8_1: file(relativePath: { eq: "collections/lost-and-found/Look8_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look8_2: file(relativePath: { eq: "collections/lost-and-found/Look8_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look9_1: file(relativePath: { eq: "collections/lost-and-found/Look9_1.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look9_2: file(relativePath: { eq: "collections/lost-and-found/Look9_2.jpg" }) {
      ...fluidImageLostAndFound
    }
    Look9_3: file(relativePath: { eq: "collections/lost-and-found/Look9_3.jpg" }) {
      ...fluidImageLostAndFound
    }
  }
`
