import React from "react"

const VideoModal = ({videoId, videoModalOpen, toggleVideoModal}) => (
  <div className={`modal ${videoModalOpen ? 'is-active' : ''}`}>
    <div className="modal-background"></div>
    <div className="modal-content">
      { videoModalOpen &&
          <div className="videoWrapper">
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${videoId}?controls=1&autoplay=1&rel=0&modestbranding=1"`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      }
    </div>
    <button className="modal-close is-large" aria-label="close" onClick={toggleVideoModal}></button>
  </div>
)

export default VideoModal